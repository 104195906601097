import React from "react";
import "./Footer.css";

function Footer() {
	return (
		<div className="footer__text">
			<p>About</p>
			<p>FAQ</p>
			<p>Legal</p>
		</div>
	);
}

export default Footer;
